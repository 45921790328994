<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <div class="row">
            <div class="col-md-12">
                <FilterProgram
                    class="mr-2"
                    v-model:value="state.params.program"/>
                <FilterRegional
                    class="mr-2"
                    placeholder="Pilih Regional Toko"
                    v-model:value="state.params.region"/>
                <FilterToko
                    class="mr-2"
                    v-model:value="state.params.toko"/>
                <FilterArea
                    class="mr-2"
                    placeholder="Pilih Area Toko"
                    v-model:value="state.params.area"
                    v-model:area="state.params.area"
                    v-model:region="state.params.region"/>
            </div>
            <div class="col-md-12 mt-2">
                <FilterPeriodeTransaksi
                    default_monthly
                    :mode="null"
                    class="mr-2"
                    status="0"
                    v-model:value="state.params.periode"/>
                <FilterLoyaltyRuleName
                    class="mr-2"
                    v-model:program="state.params.program"
                    v-model:value="state.params.rule"/>
                <!-- <AInputSearch
                class="mr-2"
                v-model:value="state.params.search"
                placeholder="Cari..."
                style="width:300px"/> -->
                <AButton
                    class="mr-2"
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </div>
        </div>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <ASpace>
                    <ADropdown
                        v-if="state.isUserPic">
                        <template #overlay>
                            <AMenu @click="btnApprove">
                                <AMenuItem key="all">
                                    All Approve
                                </AMenuItem>
                                <AMenuItem key="checked">
                                    Approve
                                </AMenuItem>
                            </AMenu>
                        </template>
                        <AButton
                            :loading="state.isApprove"
                            type="primary"
                            title="Approve">
                            <span class="fa fa-check mr-2" aria-hidden="true"/> Approve
                            <DownOutlined />
                        </AButton>
                    </ADropdown>
                    <ADropdown
                        v-if="state.isUserPic">
                        <template #overlay>
                            <AMenu @click="btnReject">
                                <AMenuItem key="all">
                                    All Reject
                                </AMenuItem>
                                <AMenuItem key="checked">
                                    Reject
                                </AMenuItem>
                            </AMenu>
                        </template>
                        <AButton
                            :loading="state.isReject"
                            type="danger"
                            title="Reject">
                            <span class="fa fa-ban mr-2" aria-hidden="true"/>
                            Reject
                            <DownOutlined />
                        </AButton>
                    </ADropdown>
                    <ATooltip
                        v-if="state.isUserPic"
                        title="Upload Dokumen"
                        placement="left">
                        <AButton
                            type="primary"
                            @click="btnUpload">
                            <i class="fa fa-upload" aria-hidden="true"></i>
                        </AButton>
                    </ATooltip>
                    <DownloadExcel
                        :url="state.endpoint"
                        :params="state.params"
                        namefile="Approval-Poin"
                        @errors="errorMessage"/>
                </ASpace>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                row-key="history_id"
                :columns="state.columns"
                :data-source="state.data"
                :row-selection="{ selectedRowKeys: state.selectedRowKeys, onChange: onSelectChange, getCheckboxProps: onDisabledCheckbox }"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #rule="{ record }">
                    <span>
                        <a-tooltip title="Detail">
                            <a-button
                                class="button"
                                size="small"
                                @click="detailByRule(record)">
                                {{ record.rule }}
                            </a-button>
                        </a-tooltip>
                    </span>
                </template>
            </MdTable>
        </div>

        <!-- reject note -->
        <AModal
            v-model:visible="visibleRejectModal"
            title="Konfirmasi Reject"
            @ok="handleOkRejectModal">
            <AForm
                class="myform"
                :wrapper-col="{ span: 14 }"
                :scroll-to-first-error="true">
                <a-row class="form-row">
                    <a-col :sm="24">
                        <AFormItem
                            label="Note"
                            label-align="left"
                            :label-col="{ sm: { span: 4 } }"
                            :wrapper-col="{ sm: { span: 24 - 4 } }"
                            required
                            has-feedback>
                            <ATextarea
                                v-model:value="state.formStatus.note"
                                required />
                        </AFormItem>
                    </a-col>
                </a-row>
            </AForm>
        </AModal>

        <!-- details by rule name -->
        <DetailByRuleModal
            v-if="visibleRuleModal"
            v-model:visible="visibleRuleModal"
            v-model:item="visibleRuleItemModal"/>

        <!-- upload -->
        <ApprovalUpload
            v-if="visibleUploadModal"
            v-model:visible="visibleUploadModal"
            @success="fetchDataList"/>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import { DownOutlined } from '@ant-design/icons-vue'
import FilterProgram from '@/components/filter/FilterProgram'
import FilterToko from '@/components/filter/FilterToko'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterArea from '@/components/filter/FilterArea'
import FilterPeriodeTransaksi from '@/components/filter/FilterPeriodeTransaksi'
import FilterLoyaltyRuleName from '@/components/filter/FilterLoyaltyRuleName'
import DetailByRuleModal from './DetailByRuleModal'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import ApprovalUpload from './ApprovalUpload'

export default defineComponent({
    components: {
        DownOutlined,
        FilterProgram,
        FilterToko,
        FilterRegional,
        FilterArea,
        FilterPeriodeTransaksi,
        FilterLoyaltyRuleName,
        DetailByRuleModal,
        DownloadExcel,
        ApprovalUpload,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'Periode',
                    dataIndex: 'period_name',
                },
                {
                    title: 'Kode Program',
                    dataIndex: 'program_code',
                },
                {
                    title: 'Nama Program',
                    dataIndex: 'program_name',
                },
                {
                    title: 'Tanggal',
                    dataIndex: 'date',
                },
                {
                    title: 'Area Toko',
                    dataIndex: 'customer_area',
                },
                {
                    title: 'Kode Toko',
                    dataIndex: 'customer_code',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'customer_name',
                },
                {
                    title: 'No Transaksi',
                    dataIndex: 'no_transaction',
                },
                {
                    title: 'Kode Produk',
                    dataIndex: 'product_code',
                },
                {
                    title: 'Nama Produk',
                    dataIndex: 'product_name',
                },
                {
                    title: 'QTY ZAK',
                    dataIndex: 'qty_zak',
                },
                {
                    title: 'QTY TON',
                    dataIndex: 'qty_ton',
                },
                {
                    title: 'Poin',
                    dataIndex: 'points',
                },
                {
                    title: 'UOM Program',
                    dataIndex: 'point_uom_name',
                },
                {
                    title: 'Nama Rule',
                    slots: { customRender: 'rule' },
                },
                {
                    title: 'Status',
                    dataIndex: 'point_status',
                },
            ],
            columnChild: [
                {
                    title: 'Periode',
                    dataIndex: 'period_name',
                },
                {
                    title: 'Kode Program',
                    dataIndex: 'program_code',
                },
                {
                    title: 'Nama Program',
                    dataIndex: 'program_name',
                },
                {
                    title: 'Kode Toko',
                    dataIndex: 'customer_code',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'customer_name',
                },
                {
                    title: 'No Transaksi',
                    dataIndex: 'no_transaction',
                },
                {
                    title: 'Kode Produk',
                    dataIndex: 'product_code',
                },
                {
                    title: 'Nama Produk',
                    dataIndex: 'product_name',
                },
                {
                    title: 'QTY ZAK',
                    dataIndex: 'qty_zak',
                },
                {
                    title: 'QTY TON',
                    dataIndex: 'qty_ton',
                },
                {
                    title: 'Poin',
                    dataIndex: 'points',
                },
                {
                    title: 'UOM Program',
                    dataIndex: 'point_uom_name',
                },
                {
                    title: 'Nama Rule',
                    dataIndex: 'rule',
                },
                {
                    title: 'Status',
                    dataIndex: 'point_status',
                },
            ],
            endpoint: '/api/loyalty-points/conversion/v2/list-approval',
            endpoint_pic: '/api/loyalty-program-pic-akses',
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isApprove: false,
            isReject: false,
            isUserPic: false,
            params: {
                search: '',
                region: [],
                area: [],
                toko: [],
                program: [],
                rule: [],
                periode: null,
                page: 1,
                "per-page": 10,
            },
            selectedRows: [],
            selectedRowKeys: [],
            formStatus: {
                note: null,
                approval_status: null,
            },
        })

        const onSelectChange = (keys, rows) => {
            state.selectedRowKeys = keys
            state.selectedRows = rows
        }

        const onDisabledCheckbox = (record) => {
            return {
                disabled: !record.valid,
            }
        }

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.endpoint, {
                    params: state.params,
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    let parents = []

                    items.forEach((item, index) => {
                        parents.push({
                            ...item,
                            valid: true,
                        })

                        if (item.events.length) {
                            item.events.forEach((child, i) => {
                                parents.push({
                                    ...child,
                                    valid: false,
                                })
                            })
                        }
                    })

                    state.data = parents
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // fetch user pic
        const fetchUserPic = () => {
            apiClient
                .get(state.endpoint_pic)
                .then(({ data }) => {
                    state.isUserPic = data
                })
        }

        const btnApprove = (field) => {
            if (state.selectedRowKeys.length === 0 && field.key == 'checked') {
                Modal.warning({
                    content: 'Centang dahulu data yang akan diperbarui!',
                })
                return
            }

            Modal.confirm({
                title: 'Konfirmasi Approve',
                content: 'Apakah anda ingin approve?',
                onOk() {
                    state.formStatus.approval_status = 1 // approve
                    sendPost(field)
                },
                onCancel() { },
            })
        }

        const sendPost = (field) => {

            let data = {
                ...state.formStatus,
                allData: false,
                filters: {
                    ...state.params,
                    ...state.formStatus,
                },
            }

            let rowData = []
            if (field.key == 'checked') {
                state.selectedRows.forEach((item, index) => {
                    rowData.push({
                        ...state.formStatus,
                        customer_code: item.customer_code,
                        program_id: item.program_id,
                        points: item.points,
                        point_uom_id: item.point_uom_id,
                        period_id: item.period_id,
                        trans_sales_id: item.trans_sales_id,
                        conversion_rule: item.rule,
                        no_transaction: item.no_transaction,
                        lt_status: item.lt_status,
                        history_id: item.history_id,
                    })
                });

                data = {
                    data: rowData,
                }
            } else {
                data.allData = true
            }

            if (state.formStatus.approval_status) {
                state.isApprove = true
            } else {
                state.isReject = true
            }

            apiClient.post('/api/loyalty-points', data)
                .then(response => {
                    state.selectedRowKeys = []
                    state.formStatus.note = null
                    fetchDataList()
                    message.success('Berhasil mengirim status')
                })
                .catch(e => message.error('Gagal mengirim status!'))
                .finally(() => {
                    state.isApprove = false
                    state.isReject = false
                    visibleRejectModal.value = false
                })
        }

        // handle reject note
        const visibleRejectModal = ref(false);

        const btnReject = (field) => {
            if (state.selectedRowKeys.length === 0 && field.key == 'checked') {
                Modal.warning({
                    content: 'Centang dahulu data yang akan diperbarui!',
                })
                return
            }
            
            state.formStatus.field = field
            visibleRejectModal.value = true
        }

        const handleOkRejectModal = () => {
            state.formStatus.approval_status = 0 // reject
            sendPost(state.formStatus.field)
        };

        // handle detail by rule name
        const visibleRuleModal = ref(false);
        const visibleRuleItemModal = ref(null);

        const detailByRule = (item) => {
            visibleRuleModal.value = true
            visibleRuleItemModal.value = item
        }

        // handle upload
        const visibleUploadModal = ref(false);

        const btnUpload = () => {
            visibleUploadModal.value = true
        }

        // handle vue
        onMounted(() => {
            fetchDataList()
            fetchUserPic()
        })

        return {
            visibleRejectModal,
            handleOkRejectModal,
            btnApprove,
            btnReject,
            fetchDataList,
            state,
            // rowSelection,
            handleTableChange,
            onSelectChange,
            onDisabledCheckbox,
            visibleRuleModal,
            visibleRuleItemModal,
            detailByRule,
            errorMessage,
            visibleUploadModal,
            btnUpload,
        }
    },
})
</script>
<style scoped>
.card-body {
    margin-left: 20px;
}

.button {
    margin: 0 3px;
}
</style>
